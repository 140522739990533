import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Injectable()
export class ModuleGuard implements CanActivate {
  constructor(private router: Router, public api: ApiService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // return true;
    return this.api.temPermissao(next.data['area']+':'+next.data['modulo']);
    // if (!this.api.usuarioData) return false;
    // return (
    //   this.api.usuarioData.master ||
    //   this.api.usuarioData.modulos.filter(m => m.indexOf(next.data['modulo']) == 0).length > 0
    // );
  }
}
