import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { LoggedGuard } from './guards/logged.guard';
import { NotLoggedGuard } from './guards/notLogged.guard';
import { ModuleGuard } from './guards/modulo.guard';
import { ApiService } from './services/api.service';
import { ConfigService } from './services/config.service';

export const routes: Routes = [
	{
		path: 'auth',
		loadChildren: './routes/auth/auth.module#AuthModule',
		data: { pageTitle: 'Autenticação', animation: 'list' },
		canActivate: [ NotLoggedGuard ]
	},
	{
		path: 'dashboard',
		loadChildren: './routes/dashboard/dashboard.module#DashboardModule',
		data: { pageTitle: 'Automativo', animation: 'list' },
		canActivate: [ LoggedGuard ]
	},
	{
		path: 'seguranca',
		children: [
			{
				path: 'perfil-acesso',
				loadChildren: './routes/perfil-acesso/perfil-acesso.module#PerfilAcessoModule',
				data: {
					area: 'PerfilAcesso',
					modulo: 'Perfil de acesso',
					pageTitle: 'Perfil de acesso',
					animation: 'list'
				},
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'usuarios',
				loadChildren: './routes/usuario/usuario.module#UsuarioModule',
				data: { area: 'PerfilAcesso', modulo: 'Usuários', pageTitle: 'Usuários', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},
	{
		path: 'empresa',
		loadChildren: './routes/empresa/empresa.module#EmpresaModule',
		data: { modulo: 'Clientes', pageTitle: 'Clientes', animation: 'list' },
		canActivate: [ LoggedGuard, ModuleGuard ]
	},
	{
		path: 'email',
		loadChildren: './routes/master/email/email.module#EmailModule',
		data: { modulo: 'Email', pageTitle: 'Email', animation: 'list' },
		canActivate: [ LoggedGuard, ModuleGuard ]
	},

	{
		path: 'noticias',
		children: [
			{
				path: 'categorias',
				loadChildren: './routes/noticias/categorias/module#CategoriasModule',
				data: { area: 'Blog', modulo: 'Categorias das notícias', pageTitle: 'Categoria', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'noticia',
				loadChildren: './routes/noticias/noticia/module#NoticiaModule',
				data: { area: 'Blog', modulo: 'Notícias', pageTitle: 'Notícias', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'publicidade',
				loadChildren: './routes/noticias/publicidades/module#PublicidadeModule',
				data: { area: 'Blog', modulo: 'Publicidades', pageTitle: 'Publicidades', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},
	{
		path: 'ranking',
		children: [
			{
				path: 'top10',
				loadChildren: './routes/ranking/top10/module#Top10Module',
				data: { area: 'Ranking', modulo: 'Top 10', pageTitle: 'Top 10', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
		]
	},
	{
		path: 'live-social',
		children: [
			{
				path: 'live-item',
				loadChildren: './routes/live-social/live-item/module#LiveSocialModule',
				data: { area: 'Ao vivo', modulo: 'Live Social', pageTitle: 'Ao vivo', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
		]
	},
	{
		path: 'empregos',
		children: [
			{
				path: 'emprego',
				loadChildren: './routes/empregos/empregos/empregos.module#EmpregosModule',
				data: { area: 'Empregos', modulo: 'Empregos', pageTitle: 'Empregos', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'tipo-vaga',
				loadChildren: './routes/empregos/tipo-vaga/tipo-vaga.module#TipoVagaModule',
				data: { area: 'Empregos', modulo: 'Tipo de vaga', pageTitle: 'Tipo de vaga', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},

	{
		path: 'catalogo',
		children: [
			{
				path: 'produtos',
				loadChildren: './routes/catalogo/produtos/module#ProdutosModule',
				data: { area: 'Catálogo', modulo: 'Catálogo produtos', pageTitle: 'Produtos', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'categorias',
				loadChildren: './routes/catalogo/categorias/module#CategoriasModule',
				data: { area: 'Catálogo', modulo: 'Catálogo categorias', pageTitle: 'Categorias', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'campos',
				loadChildren: './routes/catalogo/camposDinamicos/module#CamposModule',
				data: {
					area: 'Catálogo',
					modulo: 'Catálogo campos dinâmicos',
					pageTitle: 'Campos Dinâmicos',
					animation: 'list'
				},
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'marcas',
				loadChildren: './routes/catalogo/marcas/module#MarcasModule',
				data: { area: 'Catálogo', modulo: 'Catálogo marcas', pageTitle: 'Marcas', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},
	// {
	//   path: "permission",
	//   loadChildren: "./routes/permission/permission.module#PermissionModule",
	//   data: { pageTitle: "Minhas permissões", animation: "list" },
	//   canActivate: [LoggedGuard]
	// },
	{
		path: 'produtos',
		children: [
			{
				path: 'categorias',
				loadChildren: './routes/produtos/categorias/module#CategoriasModule',
				data: { area: 'Produtos', modulo: 'Categorias', pageTitle: 'Usuários', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'estoque',
				loadChildren: './routes/produtos/estoque/module#EstoqueModule',
				data: { area: 'Produtos', modulo: 'Estoque', pageTitle: 'Usuários', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'compra',
				loadChildren: './routes/produtos/compra/module#CompraModule',
				data: { area: 'Produtos', modulo: 'Compras', pageTitle: 'Usuários', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'fornecedores',
				loadChildren: './routes/produtos/fornecedores/module#FornecedoresModule',
				data: { area: 'Produtos', modulo: 'Fornecedores', pageTitle: 'Fornecedores', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'cadastro',
				loadChildren: './routes/produtos/produtos/module#ProdutosModule',
				data: { area: 'Produtos', modulo: 'Produtos', pageTitle: 'Usuários', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'unidade',
				loadChildren: './routes/produtos/unidade/module#UnidadeModule',
				data: { area: 'Produtos', modulo: 'Unidade', pageTitle: 'Usuários', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'variacoes',
				loadChildren: './routes/produtos/variacoes/module#VariacoesModule',
				data: { area: 'Produtos', modulo: 'Variacoes', pageTitle: 'Variações', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'marcas',
				loadChildren: './routes/produtos/marcas/module#MarcasModule',
				data: { area: 'Produtos', modulo: 'Marcas', pageTitle: 'Marcas', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},

	{
		path: 'projetos',
		children: [
			{
				path: 'tarefas',
				loadChildren: './routes/gerenciamento/tarefas/module#TarefasModule',
				data: { area: 'Projetos', modulo: 'Tarefas', pageTitle: 'Tarefas', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'horas',
				loadChildren: './routes/gerenciamento/horas/module#HorasModule',
				data: { area: 'Projetos', modulo: 'Horas', pageTitle: 'Horas', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'relatorios-pessoal',
				loadChildren: './routes/gerenciamento/relatorios/pessoal/module#RelatoriosModule',
				data: {
					area: 'Projetos',
					modulo: 'Relatório pessoal',
					pageTitle: 'Relatório pessoal',
					animation: 'list'
				},
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'relatorios-geral',
				loadChildren: './routes/gerenciamento/relatorios/geral/module#RelatoriosGeralModule',
				data: {
					area: 'Projetos',
					modulo: 'Relatório geral',
					pageTitle: 'Relatório gerencial',
					animation: 'list'
				},
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'projetos',
				loadChildren: './routes/gerenciamento/projetos/module#ProjetosModule',
				data: { area: 'Projetos', modulo: 'Projetos', pageTitle: 'Projetos', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},

	{
		path: 'financeiro',
		children: [
			{
				path: 'tipo',
				loadChildren: './routes/financeiro/tipo-financeiro/module#TipoFinanceiroModule',
				data: {
					area: 'Financeiro',
					modulo: 'Tipo Financeiro',
					pageTitle: 'Tipo Financeiro',
					animation: 'list'
				},
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'financas',
				loadChildren: './routes/financeiro/financas/module#FinancasModule',
				data: { area: 'Financeiro', modulo: 'Finanças', pageTitle: 'Finanças', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},

	{
		path: 'vendas',
		children: [
			{
				path: 'nova-venda',
				loadChildren: './routes/vendas/nova/module#NovaVendaModule',
				data: { area: 'Vendas', modulo: 'Vendas', pageTitle: 'Usuários', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'caixa',
				loadChildren: './routes/vendas/caixa/module#CaixaModule',
				data: { area: 'Vendas', modulo: 'Caixa', pageTitle: 'Usuários', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'nfce',
				loadChildren: './routes/vendas/nfce/module#NFCeModule',
				data: {
					area: 'Vendas',
					modulo: 'Emissão de Nota Fiscal',
					pageTitle: 'Emissão de Nota Fiscal',
					animation: 'list'
				},
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},

	{
		path: 'gerencia',
		children: [
			{
				path: 'clientes',
				loadChildren: './routes/gerencia/clientes/module#ClientesModule',
				data: { area: 'Gerência', modulo: 'Clientes', pageTitle: 'Clientes', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'configuracao',
				loadChildren: './routes/gerencia/configuracao/module#ConfiguracaoModule',
				data: { area: 'Gerência', modulo: 'Configurações', pageTitle: 'Configuracao', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},
	{
		path: 'contato',
		children: [
			{
				path: 'entreemcontato',
				loadChildren: './routes/contato/entreemcontato/module#EntreEmContatoModule',
				data: { area: 'Contato', pageTitle: 'EntreEmContato', animation: 'list' },
				canActivate: [ LoggedGuard ]
			}
		]
	},

	{
		path: 'paginas',
		children: [
			{
				path: 'categorias',
				loadChildren: './routes/paginas/categorias/module#PaginaCategoriasModule',
				data: { area: 'Página', modulo: 'Categorias das páginas', pageTitle: 'Categoria', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			},
			{
				path: 'pagina',
				loadChildren: './routes/paginas/pagina/module#PaginaModule',
				data: { area: 'Página', modulo: 'Páginas', pageTitle: 'Páginas', animation: 'list' },
				canActivate: [ LoggedGuard, ModuleGuard ]
			}
		]
	},

	// rotas master
	// {
	//   path: "area",
	//   loadChildren: "./routes/master/area/area.module#AreaModule",
	//   data: { modulo: "Area", pageTitle: "Áreas", animation: "list" },
	//   canActivate: [LoggedGuard, ModuleGuard]
	// },
	// {
	//   path: "menu",
	//   loadChildren: "./routes/master/menu/menu.module#MenuModule",
	//   data: { modulo: "Menu", pageTitle: "Menus", animation: "list" },
	//   canActivate: [LoggedGuard, ModuleGuard]
	// },

	// rota dinamica
	// fim rota dinamica

	// fim rotas master
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: true,
			onSameUrlNavigation: 'reload'
		})
	],
	exports: [ RouterModule ]
	// providers: [
	//   ConfigService,
	//   { provide: APP_INITIALIZER, useFactory: configServiceFactory, deps: [Injector, ConfigService], multi: true },
	// ]
})
export class AppRoutingModule {}
