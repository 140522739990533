import { Component, ContentChildren, ViewChild, Injector } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { MatIconRegistry, MatDialog, MatSnackBar, MatSidenav } from '@angular/material';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { routeAnimations } from 'src/app/components/animations';
import { ConfigService } from 'src/app/services/config.service';
import { BasicService } from 'src/app/services/basic.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { AnimationsService } from 'src/app/services/animations.service';
// import { slideRight, slideLeft } from './components/animations';
import * as FastClick from 'fastclick';
import { ChangeEmpresaComponent } from 'src/app/components/change-empresa/change-empresa.component';

declare var cordova;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  viewProviders: [MatIconRegistry],
  animations: [routeAnimations],
})
export class AppComponent {
  @ViewChild('sidenav') sidenav: MatSidenav;

  title = 'app';
  isRoot = true;
  menu: string = 'menu';
  logged = false;
  loading = false;
  routerAnimation;
  routeData = undefined;

  usuario: any = {};

  system = {
    nome: 'Carregando...',
    logo: '',
    menus: [
      {
        icon: 'money-bill-alt',
        name: 'Vendas',
        menus: [
          {
            route: 'vendas/caixa',
            name: 'Caixa',
            icon: 'cash-register',
            area: "Vendas",
            permission: 'Vendas:Caixa',
          },
          {
            route: 'vendas/nova-venda',
            name: 'Nova Venda',
            icon: 'cart-plus',
            area: "Vendas",
            permission: 'Vendas:Vendas',
          },
          {
            route: 'vendas/nfce',
            name: 'Notas fiscais',
            icon: 'file-invoice-dollar',
            area: "Vendas",
            permission: 'Vendas:Emissão de Nota Fiscal',
          },
        ],
      },
      {
        icon: 'newspaper',
        name: 'Notícias',
        menus: [
          {
            route: 'noticias/noticia',
            name: 'Notícia',
            icon: 'newspaper',
            area: "Blog",
            permission: 'Blog:Notícias',
          },
          {
            route: 'noticias/categorias',
            name: 'Categorias',
            icon: 'layer-group',
            area: "Blog",
            permission: 'Blog:Categorias das notícias',
          }, {
            route: 'noticias/publicidade',
            name: 'Publicidade',
            icon: 'ad',
            area: "Blog",
            permission: 'Blog:Publicidades',
          }
        ],
      },
      {
        icon: 'trophy',
        name: 'Ranking',
        menus: [
          {
            route: 'ranking/top10',
            name: 'Top 10',
            icon: 'trophy',
            area: "Ranking",
            permission: 'Ranking:Top 10',
          }
        ],
      },
      {
				icon: 'video',
				name: 'Ao vivo',
				menus: [
				  {
					route: 'live-social/live-item',
					name: 'Live Social',
					icon: 'video',
					area: "Ao vivo",
					permission: 'Ao vivo:Live Social',
				  }
				],
      },
      {
        name: 'Empregos',
        menus: [
          {
            route: 'empregos/emprego',
            name: 'Empregos',
            icon: 'briefcase',
            area: "Empregos",
            permission: 'Empregos:Empregos',
          },
          {
            route: 'empregos/tipo-vaga',
            name: 'Tipo de vaga',
            icon: 'network-wired',
            area: "Empregos",
            permission: 'Empregos:Tipo de vaga',
          },
        ],
      },
      {
        icon: 'money-bill-alt',
        name: 'Catálogo',
        menus: [
          {
            route: 'catalogo/produtos',
            name: 'Produtos',
            icon: 'box',
            area: "Catálogo",
            permission: 'Catálogo:Catálogo produtos',
          },
          {
            route: 'catalogo/categorias',
            name: 'Categorias',
            icon: 'archive',
            area: "Catálogo",
            permission: 'Catálogo:Catálogo categorias',
          },
          {
            route: 'catalogo/campos',
            name: 'Campos Dinâmicos',
            icon: 'tags',
            area: "Catálogo",
            permission: 'Catálogo:Catálogo campos dinâmicos',
          },
          {
            route: 'catalogo/marcas',
            name: 'Marcas',
            icon: 'tags',
            area: "Catálogo",
            permission: 'Catálogo:Catálogo marcas',
          },
        ],
      },

      {
        icon: 'warehouse',
        name: 'Produtos',
        menus: [
          {
            route: 'produtos/cadastro',
            name: 'Produtos',
            icon: 'box',
            area: "Produtos",
            permission: 'Produtos:Produtos',
          },
          {
            route: 'produtos/estoque',
            name: 'Estoque',
            icon: 'boxes',
            area: "Produtos",
            permission: 'Produtos:Estoque',
          },
          {
            route: 'produtos/compra',
            name: 'Compra',
            icon: 'shopping-cart',
            area: "Produtos",
            permission: 'Produtos:Compras',
          },
          {
            route: 'produtos/categorias',
            name: 'Categorias',
            icon: 'archive',
            area: "Produtos",
            permission: 'Produtos:Categorias',
          },
          {
            route: 'produtos/fornecedores',
            name: 'Fornecedores',
            icon: 'handshake',
            area: "Produtos",
            permission: 'Produtos:Fornecedores',
          },
          // {
          //   route: "produtos/variacoes",
          //   name: "Variações",
          //   icon: "account_circle",
          // area: "Produtos",
          //   permission: "Produtos:Variações"
          // },
          {
            route: 'produtos/unidade',
            name: 'Unidades de medidas',
            icon: 'balance-scale',
            area: "Produtos",
            permission: 'Produtos:Unidade',
          },
          {
            route: 'produtos/marcas',
            name: 'Marcas',
            icon: 'tags',
            area: "Produtos",
            permission: 'Produtos:Marcas',
          },
        ],
      },
      {
        icon: 'dollar-sign',
        name: 'Financeiro',
        menus: [
          {
            route: 'financeiro/tipo',
            name: 'Tipos',
            icon: 'hand-holding-usd',
            area: "Financeiro",
            permission: 'Financeiro:Tipo Financeiro',
          },
          {
            route: 'financeiro/financas',
            name: 'Finanças',
            icon: 'chart-line',
            area: "Financeiro",
            permission: 'Financeiro:Finanças',
          },
        ],
      },
      {
        icon: 'user-tie',
        name: 'Gerenciamento',
        menus: [
          {
            route: 'projetos/projetos',
            name: 'Projetos',
            icon: 'briefcase',
            area: "Projetos",
            permission: 'Projetos:Projetos',
          },
          {
            route: 'projetos/horas',
            name: 'Horas',
            icon: 'clock',
            iconType: 'far',
            area: "Projetos",
            permission: 'Projetos:Horas',
          },
          {
            route: 'projetos/relatorios-pessoal',
            name: 'Relatório pessoal',
            icon: 'business-time',
            area: "Projetos",
            permission: 'Projetos:Relatório pessoal',
          },
          {
            route: 'projetos/relatorios-geral',
            name: 'Relatório geral',
            icon: 'business-time',
            area: "Projetos",
            permission: 'Projetos:Relatório geral',
          },
        ],
      },
      {
        icon: 'lock',
        name: 'Segurança',
        menus: [
          {
            route: 'seguranca/perfil-acesso',
            name: 'Perfil de acesso',
            icon: 'user-circle',
            area: "PerfilAcesso",
            permission: 'PerfilAcesso:Perfil de acesso',
          },
          {
            route: 'seguranca/usuarios',
            name: 'Usuários',
            icon: 'users',
            area: "PerfilAcesso",
            permission: 'PerfilAcesso:Usuários',
          },
        ],
      },
      {
        icon: 'users-cog',
        name: 'Gerência',
        menus: [
          {
            route: 'gerencia/clientes',
            name: 'Clientes',
            icon: 'user-tag',
            area: "Gerência",
            permission: 'Gerência:Clientes',
          },
          {
            route: 'gerencia/configuracao/edit/minha-empresa',
            name: 'Configurações',
            icon: 'user-tag',
            area: "Gerência",
            permission: 'Gerência:Configurações',
          },
        ],
      },
      {
        // icon: "envelope",
        // name: "Contato",
        // menus: [
        //   {
        route: 'contato/entreemcontato',
        name: 'Entre em contato',
        area: "Contato",
        icon: 'envelope',
        // permission: "Entre em Contato"
        // }
        // ]
      },
      {
        icon: 'newspaper',
        name: 'Páginas',
        menus: [
          {
            route: 'paginas/pagina',
            name: 'Página',
            icon: 'newspaper',
            area: "Página",
            permission: 'Página:Páginas',
          },
          {
            route: 'paginas/categorias',
            name: 'Categorias',
            icon: 'layer-group',
            area: "Página",
            permission: 'Página:Categorias das página',
          }
        ],
      },
    ],
  };

  snackBarVerify: any;
  route;

  constructor(
    private injector: Injector,
    private configService: ConfigService,
    private router: Router,
    iconRegistry: MatIconRegistry,
    private basicService: BasicService,
    sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public apiService: ApiService,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    public animationService: AnimationsService,
    private titleService: Title
  ) {
    // animationService.updateRouteAnimationType(true, true);
    iconRegistry.addSvgIcon('back', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/back.svg'));

    this.activatedRoute.data.subscribe(res => {
      this.routeData = res;
    });
    // isso serve para quando troca de empresa
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    document.removeEventListener(
      'backbutton',
      e => {
        e.preventDefault();
        if (this.menu == 'back') this.goBack();
      },
      false
    );

    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isRoot = this.router.url.match(/\//g).length <= 1;
        var r = this.router.url.split('/');
        //  r.splice(0, 1);
        if (r[1] == 'projetos' && r[2] == 'tarefas') this.route = 'projetos/projetos';
        else this.route = r.length > 2 ? r[1] + '/' + r[2] : r[1];
        // this.route = r.join('/');
      }
      if (typeof cordova !== 'undefined')
        document.addEventListener(
          'deviceready',
          function () {
            if (navigator && navigator['splashscreen']) navigator['splashscreen'].hide();
            FastClick['attach'](document.body);
          },
          false
        );
      // else {
      //   FastClick['attach'](document.body);
      // }
    });

    this.apiService.begin().subscribe(response => {
      this.system.nome = response.nome;
      this.system.logo = response.logo;
      this.titleService.setTitle(this.system.nome);
    });

    authService.isLoggedIn.subscribe(
      res => {
        this.logged = res;
        this.usuario = this.apiService.usuarioData;
        if (this.apiService.usuarioData) this.system.nome = this.apiService.usuarioData.empresa;
        // if (!this.usuario) {
        //   this.logout();
        // }
        if (!this.logged) this.loading = false;
        else this.initPlatform();
      },
      err => {
        this.loading = false;
      }
    );
    this.basicService.onLoading.subscribe(async l => {
      this.loading = await l;
    });
    console.log('app component');
    this.usuario = this.apiService.usuarioData;

    if (navigator && (<any>navigator).splashscreen) (<any>navigator).splashscreen.hide();
  }

  ngOnInit() {
    setTimeout(() => {
      if (localStorage.getItem('_option:menu_opened') == '1') {
        if (this.sidenav) this.sidenav.opened = true;
      }
    }, 1500);
  }
  ngAfterViewInit() { }

  menuChange(open) {
    localStorage.setItem('_option:menu_opened', open ? '1' : '0');
  }

  logout(sidenav = undefined) {
    if (sidenav) sidenav.opened = false;
    this.authService.logout();

    if (this.snackBarVerify != undefined) this.snackBarVerify.dismiss();
    this.router.navigate(['/auth']);
  }

  checkModule(modulos) {
    // return true;
    if (!this.usuario || !this.usuario.modulos || !this.usuario.modulos) return false;
    if (!modulos || !modulos.filter) return true;
    return (
      modulos.filter(m => this.usuario.modulos.find(mm => !m.permission || mm.indexOf(m.permission) == 0))
        .length > 0
    );
  }

  changeEmpresa() {
    let dialogRef = this.dialog.open(ChangeEmpresaComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      this.loading = true;
      this.authService.changeEmpresa(result).subscribe(
        res => {
          this.router.navigate(['/dashboard']);
          // assim ele recarrega a rota, mas nao refaz o guard
          // this.router.navigate([this.router.url]);
          this.initPlatform();
          this.snackBar.open('Empresa ativa alterada', '', { duration: 2000 });
        },
        err => {
          this.snackBar.open('Não foi possível alterar a empresa ativa', '', {
            duration: 2000,
          });
        }
      );
    });
  }

  initPlatform() {
    this.configService.reset();
    this.loading = false;
    this.usuario = this.apiService.usuarioData;
    this.loading = false;
  }

  goBack() {
    if (window.history.length > 1) {
      window.history.go(-1);
      return;
    }
    // this.menu = this.menu == "back" ? "menu" : "back";
    var index = this.router.url.indexOf('/', 1);
    var url = this.router.url.substring(0, index);
    this.router.navigate([url]);
  }

  prepareRouteTransition(outlet) {
    const animation = outlet.activatedRouteData['animation'] || {};
    return animation['value'] || null;
  }

  isMasterUser() {
    return this.usuario && this.usuario.master;
  }
}
