import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subscriber } from 'rxjs/internal/Subscriber';

@Injectable({
  providedIn: 'root'
})
export class BasicService {
  private sLoading = new BehaviorSubject<boolean>(false); // {1}
  get onLoading() {
    return this.sLoading.asObservable(); // {2}
  }
  constructor() {
    var sub: Subscriber<boolean>;
  }

  loading(isLoading) {
    this.sLoading.next(isLoading);
  }

}
