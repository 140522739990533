import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(public api: ApiService) { }

  listAll() {
    return this.api.doRequest('get', 'empresa/all');
  }
  list(filter) {
    return this.api.doRequest('get', 'empresa', filter);
  }
  getMines() {
    return this.api.doRequest('get', 'empresa/mine/');
  }
  get(id) {
    return this.api.doRequest('get', 'empresa/' + id);
  }
  delete(id) {
    return this.api.doRequest('delete', 'empresa/' + id);
  }
  update(obj) {
    return this.api.doRequest('put', 'empresa', obj);
  }
  create(obj) {
    return this.api.doRequest('post', 'empresa', obj);
  }
}
