import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EmpresaService } from 'src/app/routes/empresa/empresa.service';

@Component({
  selector: 'change-empresa',
  templateUrl: 'change-empresa.component.html',
})
export class ChangeEmpresaComponent {

  empresas = [];
  IdEmpresa: any;

  constructor(
    public dialogRef: MatDialogRef<ChangeEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public api: EmpresaService
  ) {
    this.loadEmpresas();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  loadEmpresas() {
    this.api.getMines().subscribe(res => {
      this.empresas = res;
    }, err => {
    });
  }
}

