import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subscriber } from 'rxjs/internal/Subscriber';
import { ApiService } from './api.service';
import { share } from 'rxjs/internal/operators/share';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  constructor(public api: ApiService) {}

  forgot(email) {
    return this.api.doRequest('post', 'esqueciSenha', { email: email });
  }

  checkToken(token) {
    return this.api.doRequest('post', 'esqueceuSenha', { token: token });
  }

  reset(token, password) {
    return this.api.doRequest('post', 'alterarSenha', { token: token, pass: password });
  }

  register(obj: { email; name; pass; phone }) {
    var sub: Subscriber<boolean>;
    var obs = new Observable<boolean>(res => {
      sub = res;
    });

    this.api.doRequest('post', 'register', obj).subscribe(
      usuario => {
        sub.next(usuario);
        this.loggedIn.next(true);
      },
      usuarioErr => {
        sub.error(usuarioErr);
        this.loggedIn.next(false);
      }
    );
    return obs;
  }

  changeEmpresa(id) {
    var sub: Subscriber<boolean>;
    var obs = new Observable<boolean>(res => {
      sub = res;
    });
    // this.usuario.getIdToken().then(token => {
    this.api.reLogin(id).subscribe(
      login => {
        this.api.usuarioData = login;
        this.loggedIn.next(true);
        sub.next(login);
      },
      err => {
        this.loggedIn.next(false);
        sub.next(false);
      }
    );
    return obs;
  }

  relogin() {
    var obs = this.api.reLogin();
    obs.subscribe(
      res => {
        this.loggedIn.next(true);
      },
      err => {
        this.loggedIn.next(false);
      }
    );
    return obs;
  }

  login(email, pass) {
    var obs = this.api.doLogin(email, pass);
    obs.subscribe(
      res => {
        this.loggedIn.next(true);
      },
      err => {
        this.loggedIn.next(false);
      }
    );
    return obs;
  }

  logout() {
    this.api.token = null;
    this.api.usuarioData = null;
    localStorage.removeItem('__token');
    this.loggedIn.next(false);
    // return this.afAuth.auth.signOut();
  }
}
