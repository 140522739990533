import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faMoneyBillAlt,
  faCashRegister,
  faCartPlus,
  faBox,
  faBoxes,
  faArchive,
  faHandshake,
  faDiceD20,
  faBalanceScale,
  faLock,
  faUserCircle,
  faUsers,
  faBrain,
  faMugHot,
  faHandsHelping,
  faUsersCog,
  faStoreAlt,
  faUserTag,
  faCogs,
  faTags,
  faWarehouse,
  faShoppingCart,
  faEnvelope,
  faCommentAlt,
  faDollarSign,
  faHandHoldingUsd,
  faChartLine,
  faChevronDown,
  faUserTie,
  faBriefcase,
  faTasks,
  faPlus,
  faPen,
  faChevronLeft,
  faChevronRight,
  faPause,
  faFileInvoiceDollar,
  faBusinessTime,
  faLayerGroup, 
  faNewspaper, 
  faAd, 
  faNetworkWired, 
  faUser,
  faBookOpen,
  faMusic,
  faTrophy,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import {
  faListAlt,
  faTrashAlt,
  faCheckSquare,
  faSquare,
  faClock,
  faSave,
} from '@fortawesome/free-regular-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class MyFontAwesomeModule {
  constructor() {
    library.add(faMoneyBillAlt);
    library.add(faCashRegister);
    library.add(faCartPlus);
    library.add(faBox);
    library.add(faBoxes);
    library.add(faArchive);
    library.add(faHandshake);
    library.add(faDiceD20);
    library.add(faBalanceScale);
    library.add(faLock);
    library.add(faUserCircle);
    library.add(faUsers);
    library.add(faBrain);
    library.add(faMugHot);
    library.add(faHandsHelping);
    library.add(faListAlt);
    library.add(faUsersCog);
    library.add(faStoreAlt);
    library.add(faCogs);
    library.add(faTags);
    library.add(faWarehouse);
    library.add(faUserTag);
    library.add(faShoppingCart);
    library.add(faTrashAlt);
    library.add(faEnvelope);
    library.add(faCommentAlt);
    library.add(faDollarSign);
    library.add(faHandHoldingUsd);
    library.add(faChartLine);
    library.add(faSquare);
    library.add(faCheckSquare);
    library.add(faChevronDown);
    library.add(faUserTie);
    library.add(faBriefcase);
    library.add(faTasks);
    library.add(faPlus);
    library.add(faPen);
    library.add(faClock);
    library.add(faChevronLeft);
    library.add(faChevronRight);
    library.add(faPause);
    library.add(faFileInvoiceDollar);
    library.add(faSave);
    library.add(faBusinessTime);
    library.add(faLayerGroup);
    library.add(faNewspaper);
    library.add(faAd);
    library.add(faNetworkWired);
    library.add(faBookOpen);
    library.add(faMusic);
    library.add(faTrophy);
    library.add(faVideo);
  }
}
