import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscriber } from 'rxjs/internal/Subscriber';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _configData: any;
  private _promise: Promise<any>;
  private _promiseDone: boolean = false;

  constructor(private injector: Injector) {
    ;
  }

  reset() {
    this._promiseDone = false;
    this._promise = null;
  }

  loadConfig(): Promise<any> {
    this._configData = null;

    if (this._promiseDone) {
      return Promise.resolve();
    }

    if (this._promise != null) {
      return this._promise;
    }

    this._promise = new Promise((resolve, reject) => {
      var api = this.injector.get(ApiService);
      api.begin().subscribe(data => {
        this._configData = data;
        api.system = data;
        this._promiseDone = true;
        resolve(data);
      }, err => {
        reject(err);
      });
    });
    return this._promise;
  }

  get configData(): any {
    return this._configData;
  }
}
