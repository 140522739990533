import { Directive, Input, ElementRef, Renderer } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Directive({
  selector: '[allowed]',
})
export class AllowedDirective {
  @Input('allowed') allowed: string[] | string = [];

  constructor(public apiService: ApiService, private el: ElementRef, private renderer: Renderer) {}

  ngAfterContentInit() {
    if (!this.checkModule()) {
      this.renderer.setElementStyle(this.el.nativeElement, 'display', 'none');
    }
  }

  checkModule() {
    if (this.apiService.usuarioData.master) return true;
    if (!this.allowed || this.allowed.length == 0 || !this.allowed[0]) return true;
    if (!this.apiService.usuarioData || !this.apiService.usuarioData.modulos) return false;
    var allowed = typeof this.allowed === 'string' ? [this.allowed] : this.allowed;
    return allowed.filter(m => this.apiService.usuarioData.modulos.find(mm => mm.indexOf(m) == 0)).length > 0;
  }
}
