import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EmpresaService } from 'src/app/routes/empresa/empresa.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'message-modal',
  templateUrl: 'message-modal.component.html',
})
export class MessageModalComponent {

  constructor(
    public dialogRef: MatDialogRef<MessageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public api: ApiService
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

