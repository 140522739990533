import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { LoggedGuard } from './guards/logged.guard';
import { AuthService } from './services/auth.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NotLoggedGuard } from './guards/notLogged.guard';
import { BasicService } from './services/basic.service';
import { ApiService } from './services/api.service';
import { HttpModule } from '@angular/http';
import { ChangeEmpresaComponent } from './components/change-empresa/change-empresa.component';
import { ModuleGuard } from './guards/modulo.guard';
import { AddEmpresaComponent } from './components/add.empresa/add-empresa.component';
import { DirectivesModule } from './directives/directives.module';
import { MAT_DATE_LOCALE, MAT_LABEL_GLOBAL_OPTIONS, DateAdapter, MatPaginatorIntl } from '@angular/material';
import { TextMaskModule } from 'angular2-text-mask';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { AnimationsService } from './services/animations.service';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DateFormat } from './date.format';
import { getPtPaginatorIntl } from "./components/ptbr-paginator-intl";
import { CustomPaginator } from './components/base.list/paginator';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, ChangeEmpresaComponent, AddEmpresaComponent, MessageModalComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpModule,
    HttpClientModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    NgxDatatableModule,
    AppRoutingModule,
    ReactiveFormsModule,
    DirectivesModule,
    TextMaskModule,
    FontAwesomeModule,
    CurrencyMaskModule
  ],
  providers: [
    LoggedGuard,
    NotLoggedGuard,
    ModuleGuard,
    AuthService,
    AnimationsService,
    BasicService,
    ApiService,
    { provide: DateAdapter, useClass: DateFormat },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } },
    { provide: MatPaginatorIntl, useValue: getPtPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ChangeEmpresaComponent, AddEmpresaComponent, MessageModalComponent],
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale('pt-BR'); // DD/MM/YYYY
  }
}


