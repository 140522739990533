import { NgModule } from '@angular/core';

import { AllowedDirective } from './allowed.directive';

@NgModule({
    declarations: [
        AllowedDirective
    ],
    exports: [
        AllowedDirective
    ]
})
export class DirectivesModule { }
