import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BasicService } from './basic.service';
import { share } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  URL = environment.url;
  token;
  usuarioData: {
    name: string;
    email: string;
    modulos: string[];
    empresa: string;
    code: string;
    master: boolean;
  };

  public system: {
    name: string,
    icon: string,
    logo: string,
    logoVertical: string,
    tema: string,
    storageUrl: string,
    projetoId: string
  };

  constructor(private basicService: BasicService, private http: HttpClient, private router: Router) {
    this.token = localStorage.getItem('__token');
  }

  public temPermissao(modulo) {
    if (!this.usuarioData)
      return this.reLogin().pipe(
        map(res => {
          return (
            this.usuarioData.master || this.usuarioData.modulos.filter(m => m.indexOf(modulo) == 0).length > 0
          );
        })
      );
    return this.usuarioData.master || this.usuarioData.modulos.filter(m => m.indexOf(modulo) == 0).length > 0;
  }

  beginBuffer;
  public begin() {
    if (this.beginBuffer)
      return this.beginBuffer;
    this.beginBuffer = this.doRequest('get', 'system/begin').pipe(share());
    this.beginBuffer.subscribe(response => {
      this.system = response;
      document.getElementById('favicon')['href'] = response.favicon;
      document.body.classList.add('tema-' + response.tema);
    })
    return this.beginBuffer;
  }

  public doLogin(email, pass) {
    return this.doRequest('post', 'login', { email: email, pass: pass });
  }
  public reLogin(id = null) {
    return this.doRequest('put', 'login', { IdEmpresa: id });
  }

  public doRequest(
    method: 'post' | 'put' | 'delete' | 'get',
    url,
    data: any = null,
    baseUrl = this.URL
  ): Observable<any> {
    return this.request(method, url, data, baseUrl);
  }

  private request(method: 'post' | 'put' | 'delete' | 'get', url, data: any = null, baseUrl = this.URL) {
    if (!data) data = {};
    let params;
    if (method == 'get' && data) {
      params = data;
    }

    var obs: Observable<any>;
    var headers: HttpHeaders = this.token
      ? new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': this.token,
      })
      : new HttpHeaders({
        'Content-Type': 'application/json',
      });

    const httpOptions = {
      body: data,
      headers: headers,
      observe: <any>'response',
      params: params,
      responseType: <any>'json',
    };
    // if (method == 'get' || method == 'delete') {
    obs = this.http
      .request(method, baseUrl + url, httpOptions)
      .pipe(
        map((res: any) => {
          if (res.status === 444) {
            this.router.navigate(['/auth']);
            return;
          }

          if (res.status < 200 || res.status >= 300) {
          } else {
            // quando loga ele retorna no header o token
            var headers = res.headers;
            if (headers && headers.get('x-access-token')) {
              // só retorna no login e reloagin
              this.usuarioData = res.body;
              this.token = headers.get('x-access-token');
              localStorage.setItem('__token', headers.get('x-access-token'));
            }
          }
          return res.body;
        })
      )
      .pipe(share());
    // } else obs = this.http[method](this.URL + url, data, httpOptions).pipe(share());

    // obs.subscribe(callback);
    obs.pipe(catchError(this.handleError));

    return obs;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
